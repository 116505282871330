(function($){
	$('.innerLink[href^="#"]').click(function () {
		smooth(this);
		return false;
	});

	$('.pageTop a').click(function () {
		animateoffset(0);
		return false;
	});

	//smoothScroll
	function smooth(target){
		var href = $(target).attr('href');//引数targetが持つhref値を取得
		//var offset = $('#pageHeader').height();
		var offset = 0;//何px下に下げるかの数値
		var top = $(href).offset().top - offset;
		animateoffset(top);//実際にスクロールさせる関数animateoffsetを実行
	}
	function animateoffset(top){
		$('html,body').animate({scrollTop:top},1000);//引数topの値までスクロールさせる
	}
	//smoothScroll

})(jQuery)


const windowH = window.innerHeight;

let scrollY = '';
window.addEventListener('scroll', function(){
	scrollY = window.pageYOffset;
});

//==================================================================================================================
//▼ Header event
//------------------------------------------------------------------------------------------------------------------
//================================================
//▼ nav open
//------------------------------------------------
//▼ header Element get
const pageHeader = document.querySelector('#pageHeader');
const navOpen = document.querySelector('#navOpen');

//▼ nav buttton clickEvent classAdd
navOpen.addEventListener('click', function(){
	pageHeader.classList.toggle('open');
});

//▼ window.pageYOffset is over window.innerHeight event classAdd
// window.addEventListener('scroll', function(){
// 	if(windowH < scrollY){
// 		pageHeader.classList.remove('active');
// 	}else {
// 		pageHeader.classList.add('active');
// 	}
// });

//=======================================================
//▼ nav--link hover
//-------------------------------------------------------

//▼ nav getElement
const navFig = document.querySelector('.nav--fig');
const navLinks = document.querySelectorAll('.nav--link');

//▼loop
navLinks.forEach((navLink)=>{

	//▼nav--link hover action
	navLink.addEventListener('mouseover',()=>{

		//▼nav--link getClass
		const navClass = navLink.className;

		//▼ navFig background change
		switch(navClass){
			case 'nav--link nav--top':
			navFig.style.cssText = `background-image: url(../../common/img/index/bg_mv.jpg)`;
				break;
			case 'nav--link nav--philosophy':
			navFig.style.cssText = `background-image: url(../../common/img/philosophy/bg_mv.jpg)`;
				break;
			case 'nav--link nav--company':
			navFig.style.cssText = `background-image: url(../../common/img/company/bg_mv.jpg)`;
				break;
			case 'nav--link nav--service':
			navFig.style.cssText = `background-image: url(../../common/img/service/bg_mv.jpg)`;
				break;
			case 'nav--link nav--product':
			navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv.jpg)`;
				break;
			case 'nav--link nav--all':
			navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv_coating.jpg)`;
				break;
			case 'nav--link nav--coating':
			navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv_coating.jpg)`;
			// navFig.style.cssText = `background-image: url(../../common/img/product_coating/bg_mv.jpg)`;
				break;
			case 'nav--link nav--plastic':
			navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv_plastic.jpg)`;
			// navFig.style.cssText = `background-image: url(../../common/img/product_plastic/bg_mv.jpg)`;
				break;
			case 'nav--link nav--electronic':
			navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv_electronic.jpg)`;
			// navFig.style.cssText = `background-image: url(../../common/img/product_electronic/bg_mv.jpg)`;
				break;
			case 'nav--link nav--outsourcing':
			navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv_outsourcing.jpg)`;
			// navFig.style.cssText = `background-image: url(../../common/img/product_outsourcing/bg_mv.jpg)`;
				break;
			case 'nav--link nav--news':
			navFig.style.cssText = `background-image: url(../../common/img/news/bg_mv.jpg)`;
				break;
			case 'nav--link nav--contact':
			navFig.style.cssText = `background-image: url(../../common/img/contact/bg_mv.jpg)`;
				break;
			case 'nav--link nav--recruit':
			navFig.style.cssText = `background-image: url(../../common/img/contact/mv_recruit.jpg)`;
				break;
			case 'nav--link nav--privacypolicy':
			navFig.style.cssText = `background-image: url(../../common/img/privacypolicy/bg_mv.jpg)`;
				break;
			case 'nav--link nav--english':
			navFig.style.cssText = `background-image: url(../../common/img/english/bg_mv.jpg)`;
				break;
		}
	});
});

//=======================================================
//▼ navFig state image
//-------------------------------------------------------
//▼get path
const path = location.pathname;

//▼ path change navFig
switch(path){
	case '/':
		navFig.style.cssText = `background-image: url(../../common/img/index/bg_mv.jpg)`;
	break;
	case '/philosophy/':
		navFig.style.cssText = `background-image: url(../../common/img/philosophy/bg_mv.jpg)`;
	break;
	case '/company/':
		navFig.style.cssText = `background-image: url(../../common/img/company/bg_mv.jpg)`;
	break;
	case '/service/':
		navFig.style.cssText = `background-image: url(../../common/img/service/bg_mv.jpg)`;
	break;
	case '/product_all/':
	case '/product_coating/':
	navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv_coating.jpg)`;
	break;
	case '/product_plastic/':
	navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv_plastic.jpg)`;
	break;
	case '/product_electronic/':
	navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv_electronic.jpg)`;
	break;
	case '/product_outsourcing/':
	navFig.style.cssText = `background-image: url(../../common/img/product/bg_mv_outsourcing.jpg)`;
	break;
	case '/product/coating/':
		navFig.style.cssText = `background-image: url(../../common/img/product_coating/bg_mv.jpg)`;
	break;
	case '/product/plastic/':
		navFig.style.cssText = `background-image: url(../../common/img/product_plastic/bg_mv.jpg)`;
	break;
	case '/product/electronic/':
		navFig.style.cssText = `background-image: url(../../common/img/product_electronic/bg_mv.jpg)`;
	break;
	case '/product/outsourcing/':
		navFig.style.cssText = `background-image: url(../../common/img/product_outsourcing/bg_mv.jpg)`;
	break;
	case '/news/':
		navFig.style.cssText = `background-image: url(../../common/img/news/bg_mv.jpg)`;
	break;
	case '/contact/':
		navFig.style.cssText = `background-image: url(../../common/img/contact/bg_mv.jpg)`;
	break;
	// case 'nav--link nav--recruit':
	// 	navFig.style.cssText = `background-image: url(../../common/img/contact/mv_recruit.jpg)`;
	// break;
	case '/privacypolicy/':
		navFig.style.cssText = `background-image: url(../../common/img/privacypolicy/bg_mv.jpg)`;
	break;
	case '/english/':
		navFig.style.cssText = `background-image: url(../../common/img/english/bg_mv.jpg)`;
	break;
}

//=======================================================
//▼ nav--link--none click
//-------------------------------------------------------
const noneNav = document.querySelector('.nav--link--none');
noneNav.addEventListener('click', ()=>{
	noneNav.classList.toggle('active');
});


// //=======================================================
// //▼mv slider
// //-------------------------------------------------------
//
// //get slider_cmn
// const mv_slider_cmn = document.querySelectorAll('.mv__slider__cmn');
// //set index count
// let count = 0;
//
// const mvSlider = () =>{
// 	//none next index
// 	if(mv_slider_cmn[count] == null){
// 		let cn = count - 1;
// 		mv_slider_cmn[cn].classList.remove('active');
// 		//reset count
// 		count = 0;
// 	}
//
// 	if(count !== 0){
// 		let cn = count - 1;
// 		mv_slider_cmn[cn].classList.remove('active');
// 	}
// 	mv_slider_cmn[count].classList.add('active');
// 	count++;
//
//
// 	setTimeout(mvSlider, 8000);
// }
// mvSlider();


//==================================================================================================================
//▼
//------------------------------------------------------------------------------------------------------------------
